<template>
  <div class="d-flex justify-center login-container">
    <v-card width="500">
        <v-card-title class="d-flex justify-center text-h4">BonBon</v-card-title>
        <form @submit.prevent>
            <v-card-text>
                <v-text-field
                    v-model="username"
                    label="Gebruikersnaam"
                    type="text"
                    required
                    color="primary"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    label="Wachtwoord"
                    type="password"
                    required
                    color="primary"
                    outlined
                ></v-text-field>
                <v-checkbox v-model="keepAlive" label="Blijf aangemeld">
                </v-checkbox>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn x-large color="primary" type="submit" @click="login">Inloggen</v-btn>
            </v-card-actions>
        </form>
        <div class="text-h6 text-center">{{ message }}</div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      keepAlive: false,
      message: ""
    }
  },
  methods: {
    async login() {
      try {
        let result = await this.$store.dispatch("loginWithCredentials", {
          username: this.username,
          password: this.password,
          keepAlive: this.keepAlive
        })
        if (result.success) {
          this.message = "Ingelogd"         
        } else {
          this.message = "Onjuiste gebruikersnaam en/of wachtwoord"
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.login-container {
  height: 500px;
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
table {
  margin: auto;
  /* width:200px;
  padding: 25% 0; */
}
#container div {
  border: 1px solid lightgray;
  background: azure;
  color: black;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 368px;
}
input {
  width: 100px;
}
</style>